import React, { useState, useEffect} from 'react';
import { useReactMediaRecorder } from "react-media-recorder-2";
import Fields from '../common/Fields';
import NewView from "../common/NewView";

const NewSoundViewMain = ({onClickPreview}) => {
  const EXAMPLE_SENTENCE = 'Hello, buzzing explorers! Welcome to \'Bee Whisperer,\' where we dive into the fascinating world of bees! I\'m [Your Name], and today we\'re kicking off an incredible journey into the lives of these tiny, yet mighty creatures. But bees face challenges, and they need our help. Each week, we\'ll explore stunning bee habitats, meet some of the heroes working to save them, and learn how we can make our backyards a paradise for these buzzing wonders. So, if you\'re ready to get up close and personal with these amazing pollinators, hit that subscribe button, ring the bell, and join me on this sweet adventure. Trust me, you won\'t want to miss a buzz! See you in the hive!'
  const [activeSound, setActiveSound] = useState({});
  const [currentBlob, setCurrentBlob] = useState(null);

  const { status, startRecording, stopRecording, mediaBlobUrl } = useReactMediaRecorder(
    {
      audio: true,
      blobPropertyBag: { type: "audio/mp4" },
    }
  );

  const onFileChange = (e) => {
    const file = e.target.files[0];
    setActiveSound({...activeSound, file: file});
  }

  useEffect(() => {
    // if new blob doesnt equal to the old blob
    if(mediaBlobUrl && mediaBlobUrl !== currentBlob) {
      fetch(mediaBlobUrl).then((r) => r.blob()).then((blob) => {
        const file = new File([blob], "recorded.mp3", {
          type: "audio/mp3",
        })

        setCurrentBlob(mediaBlobUrl);
        setActiveSound({...activeSound, file: file});
      })
    }
  }, [mediaBlobUrl, activeSound,currentBlob]);

  return (
    <NewView>
      <div className="flex justify-between items-center mb-4 space-x-20">
        <div>
          <div className="flex items-center space-x-3">
            <h1 className="text-lg font-regular m-0 mr-2">
              New Sound
            </h1>
          </div>
        </div>
      </div>
      
      <div className="h-full overflow-scroll">
        <Fields.TextField label="Name" placeholder="Sound Name" value={activeSound.name} onChange={(e) => {setActiveSound({...activeSound, name: e.target.value})}} />
        {/* <Fields.DescriptionField label="Description" placeholder="Description" value={activeSound.description} onChange={(e) => {setActiveSound({...activeSound, description: e.target.value})}} /> */}

        <div className="w-full h-auto flex flex-row text-white font-semibold text-sm items-center">
          <button className={`my-0 mr-2 h-full aspect-[1/1] ${status === "recording" ? 'bg-text-gray' : 'bg-dark-purple'} p-1 rounded-[8px]`} type="button" onClick={startRecording} disabled={status === "recording"}>Start</button>
          <button className={`my-0 mr-4 h-full aspect-[1/1] ${status !== "recording" ? 'bg-text-gray' : 'bg-dark-purple'} p-1 rounded-[8px]`} type="button" onClick={stopRecording} disabled={status !== "recording"}>Stop</button>
          <audio className="my-0 mr-2 h-10 p-1 rounded-[8px]" src={mediaBlobUrl} controls loop />
        </div>
        <div className="text-xs my-3">
          <span className="font-semibold text-left">Example: </span>{EXAMPLE_SENTENCE}
        </div>
          
        <div className="text-md font-semibold text-black text-center">Or upload a file</div>

        <Fields.FileUploadField 
          label="Choose file" 
          description="Click to upload or drag and drop" 
          info="SVG, PNG, JPG or GIF (max. 800x400px)" 
          alert={activeSound?.file ? `File: ${activeSound.file.name}` : "No file chosen yet"} 
          onChange={onFileChange}
        />
        <div className="flex flex-row space-x-4 mb-4">
          <Fields.Button type='wide-styled' label='Preview' onClick={()=>onClickPreview(activeSound)} />
        </div>
      </div>
    </NewView>
  );
};

const NewSoundView = ({
    onClickPreview
    }) => {
  return (
    <NewSoundViewMain onClickPreview={onClickPreview} />
  );
}

export default NewSoundView;