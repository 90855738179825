import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import globalConfig from './config/config';
import { authRefresh } from './utils/auth';
import "./App.css";

// components
import Home from "./components/Home/Home";
import Landing from "./components/Landing/Landing";
import UGC from "./components/UGC/UGC";

import Main from "./components/common/Main";

import Register from "./components/Auth/Register";
import Login from "./components/Auth/Login";
import Reset from "./components/Auth/Reset";
import Verify from "./components/Auth/Verify";

import Models from "./components/Models/Models";
import Voices from "./components/Voices/Voices";
import Netizens from "./components/Netizens/Netizens";
import Pipelines from "./components/Pipelines/Pipelines";
import Accounts from "./components/Accounts/Accounts";
import Settings from "./components/Settings/Settings";
import Schedules from "./components/Schedules/Schedules";
import Memories from "./components/Memories/Memories";
import Runs from "./components/Runs/Runs";
import Feedback from "./components/Feedback/Feedback";
import Stripe from "./components/Stripe/Stripe";
import AccountsReceiver from "./components/Accounts/AccountsReceiver";
import Admin from "./components/Admin/Admin";
import AdminVideos from "./components/AdminVideos/AdminVideos";
import OneOff from "./components/OneOff/OneOffDemo";
import OneOffReal from "./components/OneOff/OneOffReal";
import Share from "./components/Share/Share";
import Sounds from "./components/Sounds/Sounds";

// main app
const App = () => {
  const location = useLocation();
  const [backEvent, setBackEvent] = useState(false);
  const [backEnabled, setBackEnabled] = useState(false);
  
  // set up timer to check the token once a minute
  useEffect(() => {
    authRefresh(false);
    const interval = setInterval(() => {
      authRefresh(false);
    }, 60000);
    return () => clearInterval(interval);
  }, [location]);

  return (
    <>
      <Routes>
        <Route index element={<Home />} />
        <Route path="/register" element={<Register />} />
        <Route path="/login" element={<Login />} />
        <Route path="/reset" element={<Reset />} />
        <Route path="/verify" element={<Verify />} />
        <Route path="/share" element={<Share />} />
        <Route path="/landing-podcast-a" element={<Landing />} />
        <Route path="/ugc-a" element={<UGC platform="YouTube" gradient_from="from-gradient-red" gradient_to="to-gradient-rose" gradient_to_bg="to-[#fdf2f2]" />} />
        <Route path="/ugc-b" element={<UGC platform="TikTok" gradient_from="from-gradient-blue" gradient_to="to-gradient-purple" gradient_to_bg="to-[#f5f3ff]" />} />
        <Route path="/ugc-c" element={<UGC platform="Instagram" gradient_from="from-gradient-red" gradient_to="to-gradient-instagram-purple" gradient_to_bg="to-[#f5f3ff]" />} />
        <Route element={<Main backEnabled={backEnabled} backEvent={backEvent} setBackEvent={setBackEvent} />}>
          <Route path="/models" element={<Models backEvent={backEvent} setBackEvent={setBackEvent} setBackEnabled={setBackEnabled} />} />
          <Route path="/voices" element={<Voices backEvent={backEvent} setBackEvent={setBackEvent} setBackEnabled={setBackEnabled} />} />
          <Route path="/sounds" element={<Sounds backEvent={backEvent} setBackEvent={setBackEvent} setBackEnabled={setBackEnabled} />} />
          <Route path="/netizens" element={<Netizens backEvent={backEvent} setBackEvent={setBackEvent} setBackEnabled={setBackEnabled} />} />
          <Route path="/pipelines" element={<Pipelines backEvent={backEvent} setBackEvent={setBackEvent} setBackEnabled={setBackEnabled} />} />
          <Route path="/accounts" element={<Accounts backEvent={backEvent} setBackEvent={setBackEvent} setBackEnabled={setBackEnabled} />} />
          <Route path="/settings" element={<Settings backEvent={backEvent} setBackEvent={setBackEvent} setBackEnabled={setBackEnabled} />} />
          <Route path="/schedules" element={<Schedules backEvent={backEvent} setBackEvent={setBackEvent} setBackEnabled={setBackEnabled} />} />
          <Route path="/memories" element={<Memories backEvent={backEvent} setBackEvent={setBackEvent} setBackEnabled={setBackEnabled} />} />
          <Route path="/generate" element={<OneOffReal backEvent={backEvent} setBackEvent={setBackEvent} setBackEnabled={setBackEnabled} />} />
          <Route path="/runs" element={<Runs backEvent={backEvent} setBackEvent={setBackEvent} setBackEnabled={setBackEnabled} />} />
          <Route path="/feedback" element={<Feedback backEvent={backEvent} setBackEvent={setBackEvent} setBackEnabled={setBackEnabled} />} />
          <Route path="/stripe" element={<Stripe backEvent={backEvent} setBackEvent={setBackEvent} setBackEnabled={setBackEnabled} />} />
          <Route path="/oauth2/*" element={<AccountsReceiver />} />
          <Route path="/admin" element={<Admin />} />
          <Route path="/admin/videos" element={<AdminVideos />} />
        </Route>
      </Routes>
    </>
  );
};

export default App;
