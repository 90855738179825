import React, { useState, useRef, useEffect } from 'react';

import ListView from '../common/ListView';
import AlertView from "../common/AlertView";
import ScheduleCard from './ScheduleCard';
import NewScheduleView from './NewScheduleView';
import EditScheduleView from './EditScheduleView';

// tour
import Joyride, { ACTIONS, EVENTS, ORIGIN, STATUS, CallBackProps, LIFECYCLE } from 'react-joyride';

import axios from 'axios';
import globalConfig from '../../config/config';
import { getAuth, authHeader } from '../../utils/auth';

import NetizenCard from '../Netizens/NetizenCard';
import PipelineCard from '../Pipelines/PipelineCard';
import AccountCard from '../Accounts/AccountCard';

const Schedules = ({backEvent, setBackEvent, setBackEnabled}) => {
  const API_ENDPOINT_SCHEDULES = `${globalConfig.api}/schedules`
  const API_ENDPOINT_NETIZENS = `${globalConfig.api}/netizens`
  const API_ENDPOINT_PIPELINES = `${globalConfig.api}/pipelines`
  const API_ENDPOINT_ACCOUNTS = `${globalConfig.api}/connectedaccounts`
  const S3_PATH_PREFIX = `https://cdn1.netizens.ai`

  // get some info to know what joyride steps to show users
  const [joyrideRunning, setJoyrideRunning] = useState(true);
  const [joyrideActive, setJoyrideActive] = useState(true);
  const [steps, setSteps] = useState(globalConfig.joyride.schedules);
  const [stepIndex, setStepIndex] = useState(0);

  const [view, setView] = useState('main');
  const [netizens, setNetizens] = useState(null);
  const [netizenCards, setNetizenCards] = useState(null);

  const [publicNetizens, setPublicNetizens] = useState(null);
  const [publicNetizenCards, setPublicNetizenCards] = useState(null);

  const [pipelines, setPipelines] = useState(null);
  const [pipelineCards, setPipelineCards] = useState(null);

  const [accounts, setAccounts] = useState(null);
  const [accountCards, setAccountCards] = useState(null);

  const [activeSchedule, setActiveSchedule] = useState(null);

  const [schedules, setSchedules] = useState(null);
  const [scheduleCards, setScheduleCards] = useState(null);

  const [message, setMessage] = useState('');
  
  const [limit, setLimit] = useState(true);
  const [tag, setTag] = useState(null);
  const [footer, setFooter] = useState([]);

  // joyride is only active when there are no schedules
  useEffect(() => {
    if(schedules && schedules.length > 0) {
      setJoyrideActive(false);
    }
  }, [schedules]);

  // callback
  const handleJoyrideCallback = (data) => {
    console.log('joyride schedules callback')
    const { action, index, type, status, lifecycle } = data;
    console.log(data)

    if(lifecycle === LIFECYCLE.COMPLETE) {
      // for previous, go to previous step
      if (stepIndex > 1 && action === ACTIONS.PREV) {
        setStepIndex(Math.max(0, stepIndex - 1));
      }

      // for next, go to next step
      if ([1, 2, 3, 4, 5, 6, 7].includes(stepIndex) && [ACTIONS.NEXT, ACTIONS.CLOSE, ACTIONS.SKIP].includes(action)) {
        setStepIndex(stepIndex + 1);
      }
    }
  }

  // set limit based on account type and schedules
  useEffect(() => {
    const limits = globalConfig.limits;
    const auth = getAuth();
    const role = auth?.user?.role;

    const roleLimits = limits[role];
    if(roleLimits) {
      const curLimit = roleLimits.schedules;

      if(curLimit < 0 || schedules?.length < curLimit) {
        setLimit(false);
        setFooter([]);
      } else {
        setLimit(true);
        setFooter([
          <span>You've reached your schedule limit ({schedules?.length || 0}/{curLimit<0?'∞':curLimit}) you are only allowed to have {curLimit<0?'∞':curLimit} schedules as a {roleLimits.label} user</span>,
          <span>When you upgrade your plan, you can create more schedules.</span>
        ]);
      }

      setTag(`${roleLimits.label} ${schedules?.length || 0}/${curLimit<0?'∞':curLimit}`);
    } else {
      setLimit(true);
      setFooter([]);
    }
  }, [schedules]);

  useEffect(() => {
    loadNetizens();
    loadPipelines();
    loadAccounts();
    loadSchedules();
  }, []);

  useEffect(() => {
    switch(view) {
      case 'new':
        setBackEnabled(true);
        break;
      case 'preview':
        setBackEnabled(false);
        break;
      case 'success':
        setBackEnabled(false);
        break;
      case 'error':
        setBackEnabled(false);
        break;
      case 'edit':
        setBackEnabled(true);
        break;
      default:
        setBackEnabled(false);
        break;
    }
  }, [view, setBackEnabled]);

  useEffect(() => {
    if(backEvent) {
      switch(view) {
        case 'new':
          setView('main');
          setBackEvent(false);
          break;
        default:
          setView('main');
          setBackEvent(false);
          break;
      }
    }
  }, [view, backEvent, setBackEvent]);

  // load current user's schedules
  const loadSchedules = () => {
    axios.get(API_ENDPOINT_SCHEDULES, {
      headers: {
        ...authHeader()
      }})
      .then(response => {
        console.log('loaded user schedules', response.data.results);
        const resSchedules = response.data.results
        setSchedules(resSchedules);
        setScheduleCards(resSchedules.map(m => <ScheduleCard key={m.id} schedule={m} onClick={() => onScheduleCardClick(m)} />));
      })
      .catch(error => {
        console.error(error);
      });
  };

  // load current user's accounts
  const loadAccounts = () => {
    axios.get(API_ENDPOINT_ACCOUNTS, {
      headers: {
        ...authHeader()
      }})
      .then(response => {
        console.log('loaded user accounts', response.data.results);

        const resAccounts = response.data.results

        setAccounts(resAccounts);
        setAccountCards(resAccounts.map(m => <AccountCard key={m.id} account={m} onClick={() => onAccountCardClick(m)} />));
      })
      .catch(error => {
        console.error(error);
      });
  };

  // load available netizens for the current user
  const loadNetizens = () => {
    const {user} = getAuth();
    const user_id = user.id;
    
    return axios.get(API_ENDPOINT_NETIZENS, {
      headers: {
        ...authHeader()
      }})
      .then(response => {
        const resNetizens = response.data.results.map(m => { 
          return {
            id: m.id,
            name: m.name,
            description: m.description,
            persona: m.persona,
            model: m.model,
            voice: m.voice,
            owner: m.owner === user_id
          }
        })

        console.log(resNetizens);
        
        // set netizens
        setNetizens(resNetizens);

        // set netizen cards for public/private
        setNetizenCards(resNetizens.filter(m => m.owner).map(m => <NetizenCard key={m.id} netizen={m} onClick={() => onNetizenCardClick(m)} />));
        setPublicNetizenCards(resNetizens.filter(m => !m.owner).map(m => <NetizenCard key={m.id} netizen={m} onClick={() => onNetizenCardClick(m)} />));
      })
      .catch(error => {
        console.error(error);
      });
  };

  // load current user's pipelines
  const loadPipelines = () => {
    axios.get(API_ENDPOINT_PIPELINES, {
      headers: {
        ...authHeader()
      }})
      .then(response => {
        console.log('loaded user pipelines', response.data.results);
        const resPipelines = response.data.results
        setPipelines(resPipelines);
        setPipelineCards(resPipelines.map(m => <PipelineCard key={m.id} pipeline={m} onClick={() => onPipelineCardClick(m)} />));
      })
      .catch(error => {
        console.error(error);
      });
  };

  const onSearch = (e) => {
    //alert(`Searching...${e.target.value}`);
  };

  const onScheduleCardClick = (schedule) => {
    console.log('Schedule clicked', schedule);
    setActiveSchedule(schedule);
    setView('edit');
  }

  const onNetizenCardClick = (netizen) => {
    console.log('Netizen clicked', netizen);
  }

  const onPipelineCardClick = (pipeline) => {
    console.log('Pipeline clicked', pipeline);
  }

  const onAccountCardClick = (account) => {
    console.log('Account clicked', account);
  }

  const onClickNew = (e) => {
    if(!limit) {
      setView('new');
      setStepIndex(1);
    } else {
      alert('Upgrade your plan.');
    }
  };

  const onClickCreate = (schedule) => {
    console.log('Creating schedule...', schedule);

    handleCreate(schedule).then(() => {
      setMessage('You have successfully created a schedule');
      setView('success');
    })
    .catch(error => {
      console.error(error);
      setMessage(error?.response?.data?.message || error.message || error || 'An error occurred');
      setView('error');
    });
  }

  const onClickEdit = (schedule) => {
    console.log(`Saving schedule...`);
    console.log(schedule);

    handleUpdate(schedule).then(() => {
      setMessage('You have successfully updated the schedule');
      setView('success');
    })
    .catch(error => {
      console.error(error);
      setMessage(error?.response?.data?.message || error.message || error || 'An error occurred');
      setView('error');
    });
  }

  const onClickDelete = (schedule) => {
    console.log(`Deleting schedule...${schedule}`);

    handleDelete(schedule).then(() => {
      setMessage('You have successfully deleted the schedule');
      setView('success');
    })
    .catch(error => {
      console.error(error);
      setMessage(error?.response?.data?.message || error.message || error || 'An error occurred');
      setView('error');
    });
  }

  const onClickTrigger = (schedule) => {
    console.log(`Triggering schedule...${schedule}`);

    handleTrigger(schedule).then(() => {
      setMessage('You have successfully triggered the schedule');
      setView('success');
    })
    .catch(error => {
      console.error(error);
      setMessage(error?.response?.data?.message || error.message || error || 'An error occurred');
      setView('error');
    });
  }

  // handle create schedule
  const handleCreate = async (schedule) => {
    if (schedule) {
      let scheduleObject = {
        name: schedule.name,
        description: schedule.description,
        netizens: schedule.netizens,
        public: schedule.public,
        pipeline: schedule.pipeline,
        accounts: schedule.accounts,
        active: schedule.active,
        startDate: schedule.startDate,
        endDate: schedule.endDate,
        startTime: schedule.startTime,
        daysOfTheWeek: schedule.daysOfTheWeek,
        timesPerDay: schedule.timesPerDay
      }

      // if active schedule is an available schedule, create it
      return axios.post(API_ENDPOINT_SCHEDULES, scheduleObject, {
        headers: {
          ...authHeader()
        }
      })
      .then(response => {
        // reload schedules
        loadSchedules();

        // set user tour status if this was the first schedule
        if(schedules.length === 0) {
          const {user} = getAuth();
          let userTour = user.tour || {};
          const user_id = user.id;
          axios.patch(`${globalConfig.api}/users/${user_id}`, {
            tour: {
              ...userTour,
              schedules: true
            }
          }, {
            headers: {
              ...authHeader()
            }
          })
          .then(response => {
            console.log('user tour updated');
          })
          .catch(error => {
            console.error(error);
          });
        }
      })
      .catch(error => {
        console.error(error);
        throw error;
      });
    } else {
      return new Promise((resolve, reject) => {
        reject('Schedule object is empty');
      });
    }
  }

  // update a schedule
  const handleUpdate = (schedule) => {
    if (schedule) {
      let scheduleObject = {
        name: schedule.name,
        description: schedule.description,
        netizens: schedule.netizens,
        public: schedule.public,
        pipeline: schedule.pipeline,
        accounts: schedule.accounts,
        active: schedule.active,
        startDate: schedule.startDate,
        endDate: schedule.endDate,
        startTime: schedule.startTime,
        daysOfTheWeek: schedule.daysOfTheWeek,
        timesPerDay: schedule.timesPerDay
      }

      return axios.patch(`${API_ENDPOINT_SCHEDULES}/${activeSchedule.id}`, scheduleObject, {
          headers: {
            ...authHeader()
          }
        })
        .then(response => {
          // reload schedules
          loadSchedules();
        })
        .catch(error => {
          console.error(error);
          throw error;
        });
    } else {
      return new Promise((resolve, reject) => {
        reject('Schedule object is empty');
      });
    }
  }

  // delete a schedule
  const handleDelete = (schedule) => {
    return axios.delete(`${API_ENDPOINT_SCHEDULES}/${schedule.id}`, {
      headers: {
        ...authHeader()
      }
    })
    .then(response => {
      // reload schedules
      loadSchedules();
    })
    .catch(error => {
      console.error(error);
      throw error;
    });
  }

  // trigger a schedule
  const handleTrigger = (schedule) => {
    return axios.patch(`${API_ENDPOINT_SCHEDULES}/${schedule.id}/trigger`, {}, {
      headers: {
        ...authHeader()
      }
    })
    .then(response => {
      // reload schedules
      loadSchedules();
    })
    .catch(error => {
      console.error(error);
      throw error;
    });
  }

  return [
    (joyrideActive && <Joyride
      run={joyrideRunning}
      continuous={false}
      showProgress={true}
      callback={handleJoyrideCallback}
      steps={steps}
      stepIndex={stepIndex}
    />),(
    (view==='main' && <ListView
      view={view}
      cards={scheduleCards} 
      toggle={false} 
      title="Schedules"
      description="Find all the schedules you have created here"
      info={tag}
      label="Create Schedule"
      label1=''
      label2='Last Run'
      label3='Next Run'
      onClick={onClickNew}
      onSearch={onSearch}
      footer={footer}
      emptyTitle='No schedules created'
      emptyDescription='Click "Create new schedule" button to get started generating content automatically.'
      emptyLabel='Create new schedule'
    />) ||
    (view==='new' && <NewScheduleView
      netizens={netizens}
      pipelines={pipelines}
      accounts={accounts}
      onClickCreate={onClickCreate}
    />) ||
    (view==='success' && <AlertView
      title={"Successful"}
      description={message}
      label={"Continue"}
      onClick={()=>setView('main')}
    />) ||
    (view==='error' && <AlertView
      title={"Error"}
      description={message}
      label={"Continue"}
      onClick={()=>setView('main')}
    />) ||
    (view==='edit' && <EditScheduleView 
      schedule={activeSchedule}
      netizens={netizens}
      pipelines={pipelines}
      accounts={accounts}
      onClickSave={onClickEdit}
      onClickDelete={onClickDelete}
      onClickTrigger={onClickTrigger}
    />)
  )];
}

export default Schedules;