import React, { useState, useRef } from 'react';
import { UserPen, Mail, Eye, EyeOff } from 'lucide-react';

const Header = ({ title }) => (
    <h2 className="mt-6 text-center text-2xl font-semibold bg-clip-text text-transparent bg-gradient-to-br from-gradient-blue to-gradient-purple">
        {title}
    </h2>
);

const Description = ({ description }) => (
    <p className="mt-2 text-center text-sm text-text-gray">
        {description}
    </p>
);

const Error = ({ error }) => (
    <div className="mt-4 p-2 bg-error border-1 border-outline-dark text-black text-center rounded">
        {error}
    </div>
);

const Name = ({ label='NAME', placeholder='Enter Name', name, setName }) => (
  <div>
    <label htmlFor="name" className="text-sm font-medium text-gray-700">
      {label}
    </label>
    <div className="mt-1 relative rounded-md shadow-sm">
      <input
        id="name"
        name="name"
        type="text"
        autoComplete="name"
        required
        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        placeholder={placeholder}
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
        <UserPen className="h-5 w-5 text-gray-400" />
      </div>
    </div>
  </div>
);

const Email = ({ label='EMAIL ADDRESS', placeholder='Enter Email', email, setEmail, icon=true }) => (
    <div>
      <label htmlFor="email" className="text-sm font-medium text-gray-700">
        {label}
      </label>
      <div className="mt-1 relative rounded-md shadow-sm">
        <input
          id="email"
          name="email"
          type="email"
          autoComplete="email"
          required
          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          placeholder={placeholder}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        {icon && <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
          <Mail className="h-5 w-5 text-gray-400" />
        </div>}
      </div>
    </div>
);

const Password = ({ label='PASSWORD', placeholder='Enter Password', password, setPassword, icon=true }) => {
  const [showPassword, setShowPassword] = useState(false);
  
  return (
    <div>
      <label htmlFor="password" className="text-sm font-medium text-gray-700">
        {label}
      </label>
      <div className="mt-1 relative rounded-md shadow-sm">
        <input
          id="password"
          name="password"
          type={showPassword ? "text" : "password"}
          autoComplete="current-password"
          required
          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          placeholder={placeholder}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <div className="absolute inset-y-0 right-0 pr-3 flex items-center">
          <button
            type="button"
            onClick={() => setShowPassword(!showPassword)}
            className="text-gray-400 hover:text-gray-500 focus:outline-none"
          >
            {icon && (showPassword ? (
              <EyeOff className="h-5 w-5" />
            ) : (
              <Eye className="h-5 w-5" />
            ))}
          </button>
        </div>
      </div>
    </div>
  );
}

const Info = ({ label='Forgot Password?', link='reset', align='end', color='gray-600', hover='gray-500' }) => (
  <div className={`flex items-center justify-${align}`}>
    <div className="text-sm">
      {link && <a href={link} className={`font-medium text-${color} hover:text-${hover}`}>
        {label}
      </a>}
      {!link && label}
    </div>
  </div>
);

const DashedText = ({ text }) => (
  <div className="relative">
    <div className="absolute inset-0 flex items-center">
      <div className="w-full border-t border-gray-300"></div>
    </div>
    <div className="relative flex justify-center text-sm">
      <span className="px-2 bg-white text-gray-500">{text}</span>
    </div>
  </div>
);

const Button = ({ type='basic', label='Log into Account', onClick, action='submit', icon, css=null }) => {
  const styleMap = {
      'basic': 'w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 bg-gradient-to-br from-gradient-blue to-gradient-purple',
      'google': 'w-full flex items-center justify-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50'
  };

  return (
      <button 
        type={action}
        className={`${styleMap[type]} ${css}`} 
        onClick={onClick}
      >
        {icon}
        {label}
      </button>
  );
}

const Link = ({ label, link, color='link', hover='link-hover' }) => (
  <a href={link} className={`font-medium text-${color} hover:text-${hover}`}>
    {label}
  </a>
);

const all = {
    Header,
    Description,
    Error,
    Name,
    Email,
    Password,
    Info,
    DashedText,
    Link,
    Button
};

export default all;
