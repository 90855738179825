import React, { useState, useRef, useEffect } from 'react';

import CardView from '../common/CardView';
import NewSoundView from "./NewSoundView";
import PreviewSoundView from "./PreviewSoundView";
import AlertView from "../common/AlertView";
import EditSoundView from "./EditSoundView";
import SoundCard from './SoundCard';

import { Search, Plus, Upload, CheckCircle } from 'lucide-react';
import NoItemsIcon from '../../assets/icons_main/no_items.svg';
import { Play, Pause, Volume2, MoreVertical } from 'lucide-react';

import axios from 'axios';
import globalConfig from '../../config/config';
import { getAuth, authHeader } from '../../utils/auth';

const Sounds = ({backEvent, setBackEvent, setBackEnabled}) => {
  const API_ENDPOINT_SOUNDS = `${globalConfig.api}/sounds`
  const S3_PATH_PREFIX = `https://cdn1.netizens.ai`

  const [view, setView] = useState('main');
  const [sounds, setSounds] = useState(null);
  const [activeSound, setActiveSound] = useState(null);
  const [soundCards, setSoundCards] = useState(null);
  const [publicSoundCards, setPublicSoundCards] = useState(null);
  const [message, setMessage] = useState('');
  
  const [limit, setLimit] = useState(true);
  const [tag, setTag] = useState(null);
  const [footer, setFooter] = useState([]);

  const [uploadProgress, setUploadProgress] = useState(0);
  const [busy, setBusy] = useState(false);

  // set limit based on account type and sounds
  useEffect(() => {
    const limits = globalConfig.limits;
    const auth = getAuth();
    const role = auth?.user?.role;

    const roleLimits = limits[role];
    if(roleLimits) {
      const curLimit = roleLimits.sounds;

      if(curLimit < 0 || sounds?.filter(m=>m.owner).length < curLimit) {
        setLimit(false);
        setFooter([]);
      } else {
        setLimit(true);
        setFooter([
          <span>You've reached your sound limit ({sounds?.filter(m=>m.owner).length || 0}/{curLimit<0?'∞':curLimit}) you are only allowed to have {curLimit<0?'∞':curLimit} sounds as a {roleLimits.label} user</span>,
          <span>When you upgrade your plan, you can create more sounds.</span>
        ]);
      }

      setTag(`${roleLimits.label} ${sounds?.filter(m=>m.owner).length || 0}/${curLimit<0?'∞':curLimit}`);
    } else {
      setLimit(true);
      setFooter([]);
    }
  }, [sounds]);

  useEffect(() => {
    loadSounds();
  }, []);

  useEffect(() => {
    switch(view) {
      case 'new':
        setBackEnabled(true);
        break;
      case 'preview':
        setBackEnabled(false);
        break;
      case 'success':
        setBackEnabled(false);
        break;
      case 'edit':
        setBackEnabled(true);
        break;
      default:
        setBackEnabled(false);
        break;
    }
  }, [view, setBackEnabled]);

  useEffect(() => {
    if(backEvent) {
      setView('main');
      setBackEvent(false);
    }
  }, [backEvent, setBackEvent]);

  // load available sounds for the current user
  const loadSounds = () => {
    const {user} = getAuth();
    const user_id = user.id;
    
    axios.get(API_ENDPOINT_SOUNDS, {
      headers: {
        ...authHeader()
      }})
      .then(response => {
        const resSounds = response.data.results.map(m => { 
          return {
            id: m.id,
            name: m.name,
            description: m.description,
            active: m.active,
            file: m.file ? `${S3_PATH_PREFIX}/${m.file}` : null,
            sample: m.sample ? `${S3_PATH_PREFIX}/${m.sample}` : null,
            owner: m.owner === user_id
          }
        })

        console.log(resSounds);
        
        // set sounds
        setSounds(resSounds);

        // set sound cards for public/private
        setSoundCards(resSounds.filter(m => m.owner).map(m => <SoundCard key={m.id} sound={m} onClick={() => onCardClick(m)} />));
        setPublicSoundCards(resSounds.filter(m => !m.owner).map(m => <SoundCard key={m.id} sound={m} />));
      })
      .catch(error => {
        console.error(error);
      });
  };

  const onSearch = (e) => {
    //alert(`Searching...${e.target.value}`);
  };

  const onCardClick = (sound) => {
    setActiveSound(sound);
    setView('edit');
  }

  const onClickNew = (e) => {
    if(!limit) {
      setView('new');
    } else {
      alert('Upgrade your plan.');
    }
  };

  const onClickPreview = (sound) => {
    console.log('Previewing sound...', sound);
    setActiveSound(sound);
    setView('preview');
  }

  const onClickUpload = (sound) => {
    console.log(`Uploading sound...${sound}`);

    handleUpload(sound).then(() => {
      setMessage('You have successfully uploaded a sound');
      setView('success');
    })
    .catch(error => {
      console.error(error);
    });
  }

  const onClickEdit = (sound) => {
    console.log(`Saving sound...${sound}`);

    handleUpdate(sound).then(() => {
      setMessage('You have successfully updated the sound');
      setView('success');
    })
    .catch(error => {
      console.error(error);
    });
  }

  const onClickDelete = (sound) => {
    console.log(`Deleting sound...${sound}`);

    handleDelete(sound).then(() => {
      setMessage('You have successfully deleted the sound');
      setView('success');
    })
    .catch(error => {
      console.error(error);
    });
  }

  // create a new sound
  const handleUpload = (sound) => {
    // Reset progress
    setUploadProgress(0);

    // Create form data
    const formData = new FormData();
    formData.append('file', sound.file);
    formData.append('name', sound.name);
    formData.append('description', sound.description);

    // set busy state
    setBusy(true);

    // post to endpoint
    return axios.post(API_ENDPOINT_SOUNDS, formData, {
      headers: {
        ...authHeader(),
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: function(progressEvent) {
        const percentCompleted = Math.round((progressEvent.loaded * 80) / progressEvent.total);
        setUploadProgress(percentCompleted);
      },
    })
    .then(response => {
      setUploadProgress(100);

      // reload sounds
      loadSounds();

      // reset busy state
      setBusy(false);
    })
    .catch(error => {
      console.error(error);

      // reset busy state
      setBusy(false);

      throw error;
    });
  };

  // update a sound
  const handleUpdate = (sound) => {
    return axios.patch(
      `${API_ENDPOINT_SOUNDS}/${sound.id}`, 
      {
        name: sound.name,
        description: sound.description,
        public: sound.public
      },
      {
        headers: {
          ...authHeader()
        }
      })
      .then(response => {
        // reload sounds
        loadSounds();
      })
  }

  // delete a sound
  const handleDelete = (sound) => {
    return axios.delete(`${API_ENDPOINT_SOUNDS}/${sound.id}`, {
      headers: {
        ...authHeader()
      }
    })
    .then(response => {
      // reload sounds
      loadSounds();
    })
  }

  return (
    (view==='main' && <CardView
      view={view}
      cards={soundCards} 
      publicCards={publicSoundCards} 
      toggle={true} 
      title="Sounds"
      description="Find all the sounds you have created here"
      info={tag}
      label="Create Sound"
      onClick={onClickNew}
      onSearch={onSearch}
      footer={footer}
      emptyTitle='No sounds created'
      emptyDescription='Click "Create new sound" button to get started generating content automatically.'
      emptyLabel='Create new sound'
      emptyEnabled={false}
    />) ||
    (view==='new' && <NewSoundView
      onClickPreview={onClickPreview}
    />) ||
    (view==='preview' && <PreviewSoundView 
      name={'Storia'}
      description={'A sound created using vroidstudio'}
      sound={activeSound}
      uploadProgress={uploadProgress}
      onUpload={onClickUpload}
      onBack={()=>setView('new')}
    />) ||
    (view==='success' && <AlertView
      title={"Successful"}
      description={message}
      label={"Continue"}
      onClick={()=>setView('main')}
    />) || 
    (view==='edit' && <EditSoundView 
      sound={activeSound}
      onClickSave={onClickEdit}
      onClickDelete={onClickDelete}
    />)
  );
}

export default Sounds;