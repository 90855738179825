import React from 'react';
import { CheckCircle } from 'lucide-react';


const VideoCard = ({ url }) => {
  return (
    <video src={`${url}#t=0.1`} className="flex grow min-h-0 rounded-lg shadow-lg" controls>
        <source src={`${url}#t=0.1`} type="video/mp4" />
        Your browser does not support the video tag.
    </video>
  )
}

const OneOffAlertMain = ({ title='Title', description='Description', visible=false, url=null, onClickClose, onClickDownload }) => {
  return (
    <div className={`${visible ? '' : 'hidden'} absolute w-screen h-screen inset-0 flex items-center justify-center bg-dark-gray-transparent z-[1000]`}>
      <div className="flex h-auto w-auto">
        <div className="flex flex-col h-full max-h-screen bg-white p-8 rounded-lg text-center max-w-md w-full border rounded-[8px] border-[#E4E7EC]">
          {url && <VideoCard url={url} />}
          <h2 className="text-2xl font-bold mb-2">{title}</h2>
          <p className="text-gray-600 mb-6">{description}</p>
          <div className="flex justify-center items-center space-x-4">
            <button
              id="demo-result-close-button"
              onClick={onClickClose}
              className="w-full bg-danger py-2 px-4 rounded transition-colors text-text-secondary-light font-light"
            >
              Close
            </button>
            <button
              id="demo-result-download-button"
              onClick={onClickDownload}
              className="w-full bg-success py-2 px-4 rounded transition-colors text-text-secondary-light font-light"
            >
              Download
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const OneOffAlert = ({
    title='Title', 
    description='Description', 
    label='Label', 
    visible=false,
    url=null,
    onClickClose=()=>{},
    onClickDownload=()=>{}
    }) => {
  return (
    <OneOffAlertMain 
      title={title}
      description={description}
      label={label}
      visible={visible}
      url={url}
      onClickClose={onClickClose}
      onClickDownload={onClickDownload}
    />
  );
}

export default OneOffAlert;