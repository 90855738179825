import React, {useState} from 'react';
import Fields from '../common/Fields';

import NewView from "../common/NewView";

const EditSoundViewMain = ({sound, onClickSave, onClickDelete}) => {
  const [activeSound, setActiveSound] = useState(sound);

  return (
    <NewView>
      <div className="flex justify-between items-center mb-4 space-x-20">
        <div>
          <div className="flex items-center space-x-3">
            <h1 className="text-lg font-regular m-0 mr-2">
              Edit Sound
            </h1>
          </div>
        </div>
      </div>

      <Fields.TextField label="Name" placeholder="Sound Name" value={activeSound.name} onChange={(e) => {setActiveSound({...activeSound, name: e.target.value})}} />
      <Fields.DescriptionField label="Description" placeholder="Description" value={activeSound.description} onChange={(e) => {setActiveSound({...activeSound, description: e.target.value})}} />
      <div className="flex flex-row space-x-4 mb-4">
        <Fields.Button type='wide-styled' label='Save' onClick={()=>{onClickSave(activeSound)}} />
        <Fields.Button type='danger' label='Delete' onClick={()=>{onClickDelete(activeSound)}} />
      </div>
    </NewView>
  );
};

const EditSoundView = ({
    sound,
    onClickSave,
    onClickDelete
    }) => {
  return (
    <EditSoundViewMain sound={sound} onClickSave={onClickSave} onClickDelete={onClickDelete} />
  );
}

export default EditSoundView;