import React, { useState, useEffect } from 'react';

import config from '../../config/config';

import AuthService from '../../services/auth.service';
import OAuth2Login from 'react-simple-oauth2-login';
import LoginFields from '../Auth/LoginFields';
import SimpleIcon from "../common/SimpleIcon";
import { siGoogle } from 'simple-icons';

import moment from 'moment-timezone';

import './Landing.css';

const Landing = () => {
  const LANDING_VIDEO = 'https://cdn1.netizens.ai/tia_landing.mp4';
  const COPY = [
    `Starting a podcast is a *lot*—scripts, recording, editing? Ugh. But with netizens.ai, you skip the hassle.`,
    ` `,
    `No mic? No problem. Our AI hosts do all the talking, and you get all the credit.`,
  ];

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if(!email || !password) {
      setMessage('Please fill in all fields');
      return;
    }

    // name is part of email before the last @
    const name = email.split('@')[0];

    AuthService.register(name, email, password, moment.tz.guess())
      .then((data) => {
        if(data) {
          // Save the token and user info in local storage
          localStorage.setItem('tokens', JSON.stringify(data.tokens));
          localStorage.setItem('user', JSON.stringify(data.user));

          window.location.href = '/verify';
        } else {
          setMessage('Error registering');
        }
      })
      .catch((error) => {
        setMessage(error.response.data.message);
      })
  }

  const oauth2_onSuccessGoogle = (response) => {
    const code = response?.code

    setMessage(null);

    if (code) {
      AuthService.loginGoogle(
        code,
        (config.url+'/login').replace(/([^:]\/)\/+/g, "$1"),
        moment.tz.guess()
        ).then(
        (data) => {
          // Save the token and user info in local storage
          localStorage.setItem('tokens', JSON.stringify(data.tokens));
          localStorage.setItem('user', JSON.stringify(data.user));
          
          window.location.href = '/netizens';
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          setMessage(resMessage);
        }
      );
    } else {
      setMessage('Registration disallowed, try again later.');
    }
  };

  const oauth2_onFailure = response => console.error(response);

  return (
    <div className="flex flex-col h-screen w-screen justify-center items-center">
      <div className="flex flex-col gap-3 h-full py-3 justify-center items-center">
        <div className="flex flex-col grow-0 w-auto px-1 justify-center items-center min-h-0 min-w-0">
          <h1 className="text-2xl sm:text-4xl text-left sm:text-center px-2">Launch Your AI-Powered Podcast Instantly</h1>
          <p className="text-sm sm:text-lg text-left sm:text-center px-2">Create, manage, and publish professional-grade podcasts effortlessly using AI.</p>
        </div>

        <div className="landing-video-item flex flex-row grow-0 max-h-[40%] w-full min-h-0 min-w-0 justify-center mb-2">
          <video className="flex flex-col grow-0 h-auto w-auto object-contain min-h-0 rounded-lg" controls>
            <source src={`${LANDING_VIDEO}#t=0.1`} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>

        <div className="flex flex-col grow-0 w-auto px-2 justify-center items-center min-h-0 min-w-0 max-w-[80vh]">
          <p className="text-xs sm:text-sm font-bold text-left">{COPY.map((line, idx) => <span key={idx}>{line}<br/></span>)}</p>
        </div>

        {message && <LoginFields.Error error={message} />}
        <form className="flex flex-col gap-2 grow-0 w-auto max-w-[80vh] min-h-0 px-1" action="#" method="POST">
          <div className="flex flex-row grow w-full gap-3 min-w-0 justify-around items-center">
            <LoginFields.Email label="EMAIL ADDRESS" placeholder="Enter Email" email={email} setEmail={setEmail} icon={false} />
            <LoginFields.Password label="PASSWORD" placeholder="Enter Password" password={password} setPassword={setPassword} icon={false} />
          </div>
          <div className="flex flex-col gap-3">
            <div className="flex flex-col">
              <LoginFields.Button label="Create Account" onClick={handleSubmit} css={
                "to-link-hover from-dark-red"
              } />
            </div>
          </div>
        </form>

        <div className="flex flex-col grow-0 px-1 min-h-0">
          <LoginFields.DashedText text="Or" />
          <div className="flex flex-col min-h-0">
            <OAuth2Login
              authorizationUrl={config.google.authorization_url}
              responseType="code"
              clientId={config.google.client_id}
              redirectUri={(config.url+'/login').replace(/([^:]\/)\/+/g, "$1")}
              scope={config.google.scopes.join(' ')}
              onSuccess={oauth2_onSuccessGoogle}
              onFailure={oauth2_onFailure}
              render={({ onClick }) => {return (
                  <LoginFields.Button 
                    label="Register with Google" 
                    type="google" 
                    icon={<SimpleIcon icon={siGoogle} className="h-5 w-5 mr-2" />} 
                    onClick={onClick}
                  />
                )}
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Landing;