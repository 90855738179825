import React, {useState, useRef, useEffect} from 'react';

import Fields from '../common/Fields';
import SoundCard from './SoundCard';

import globalConfig from '../../config/config';

const SoundPreview = ({ name='Name', description='Description', sound, onUpload, onBack, uploadProgress }) => {
  const [activeSound, setActiveSound] = useState(sound);
  const [modifiedSound, setModifiedSound] = useState({});

  // on load
  useEffect(() => {
    if(activeSound && activeSound.file) {
      const audioUrl = URL.createObjectURL(activeSound.file);
      setModifiedSound({...activeSound, file: audioUrl});
    }
  }, []);

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-lg shadow-lg w-full max-w-md">
        <div className="p-6">
          <div className="flex justify-between items-center mb-4 space-x-20">
            <div>
              <div className="flex items-center space-x-3">
                <h1 className="text-lg font-regular m-0 mr-2">
                  Sound Preview
                </h1>
              </div>
            </div>
          </div>

          <div className="bg-white rounded-lg p-0 mb-6">
            {modifiedSound.file && <SoundCard sound={modifiedSound} />}
          </div>

          {uploadProgress > 0 && (
            <div className="mb-4">
              <div className="relative pt-1">
                <div className="flex mb-2 items-center justify-between">
                  <div>
                    <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-black bg-gray-200">
                      {uploadProgress}%
                    </span>
                  </div>
                </div>
                <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-gray-200">
                  <div style={{ width: `${uploadProgress}%` }} className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-black"></div>
                </div>
              </div>
            </div>
          )}

          <div className="flex flex-row space-x-4">
            <Fields.Button type='wide-styled' label='Upload sound' onClick={() => onUpload(activeSound)} />
            <Fields.Button type='wide-secondary' label='Back' onClick={onBack} />
          </div>
        </div>
      </div>
    </div>
  );
};

const PreviewSoundView = ({
    name='Name',
    description='Description',
    sound,
    uploadProgress,
    onUpload,
    onBack,
  }) => {
  return (
    <SoundPreview 
      name={name}
      description={description}
      sound={sound}
      uploadProgress={uploadProgress}
      onUpload={onUpload}
      onBack={onBack}
    />
  );
}

export default PreviewSoundView;