import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import storiaImage from '../../assets/ugc_landing/storia.png';
import img_feat1 from "../../assets/icons_landing/feat1.webp";

const UGC = ({
    platform='YouTube',
    gradient_from='from-gradient-blue',
    gradient_to='to-gradient-purple',
    gradient_to_bg='to-[#eef2ff]',
    }) => {
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="min-h-screen bg-white text-text-primary font-inter">
      {/* Preload Video Assets */}
      <link rel="preload" as="video" href="https://cdn1.netizens.ai/social1-lq.webm" type="video/webm" />
      <link rel="preload" as="video" href="https://cdn1.netizens.ai/social2-lq.webm" type="video/webm" />
      <link rel="preload" as="video" href="https://cdn1.netizens.ai/canva1.webm" type="video/webm" />
      <link rel="preload" as="video" href="https://cdn1.netizens.ai/canva2.webm" type="video/webm" />
      <link rel="preload" as="video" href="https://cdn1.netizens.ai/canva3.webm" type="video/webm" />
      
      {/* Logo */}
      <div className="absolute top-5 left-5 md:top-10 md:left-10 z-20">
        <Link to="/" className="text-2xl font-semibold hover:opacity-80 transition-opacity tracking-tight">
          <span className="text-[#1a1f36]">NETIZENS</span>
          <span className={`bg-clip-text text-transparent bg-gradient-to-r ${gradient_from} ${gradient_to}`}>.ai</span>
        </Link>
      </div>

      {/* Hero Section */}
      <div className={`w-full h-screen bg-gradient-to-b from-white ${gradient_to_bg} relative overflow-hidden`}>
        {/* Left Video */}
        <div className="absolute left-0 top-1/4 md:top-1/2 -translate-y-1/4 -translate-x-1/8 w-[300px] h-[500px] -rotate-[20deg]">
          <video
            className="w-full h-full object-cover rounded-2xl shadow-xl"
            autoPlay={isDesktop}
            loop
            muted
            playsInline
            preload={isDesktop ? "auto" : "none"}
            poster="https://cdn1.netizens.ai/social1-poster.png"
          >
            <source src="https://cdn1.netizens.ai/social1-lq.webm" type="video/webm" media="(max-width: 768px)" />
            <source src="https://cdn1.netizens.ai/social1.webm" type="video/webm" media="(min-width: 769px)" />
          </video>
        </div>

        {/* Right Video */}
        <div className="absolute right-0 top-1/2 -translate-y-1/8 translate-x-1/8 w-[300px] h-[500px] rotate-[10deg]">
          <video
            className="w-full h-full object-cover rounded-2xl shadow-xl"
            autoPlay={isDesktop}
            loop
            muted
            playsInline
            preload={isDesktop ? "auto" : "none"}
            poster="https://cdn1.netizens.ai/social2-poster.png"
          >
            <source src="https://cdn1.netizens.ai/social2-lq.webm" type="video/webm" media="(max-width: 768px)" />
            <source src="https://cdn1.netizens.ai/social2.webm" type="video/webm" media="(min-width: 769px)" />
          </video>
        </div>

        <div className="container mx-auto px-4 h-full flex items-center relative z-10">
          <div className="w-full mx-auto text-center md:bg-transparent bg-white/40 backdrop-blur-sm md:backdrop-blur-none rounded-2xl p-4 md:p-0 leading-relaxed">
            <h1 className="text-3xl md:text-6xl font-semibold mb-2 md:mb-0 text-[#1a1f36] w-[100%] mx-auto tracking-tight ">
                Turn Your <span className={`bg-clip-text text-transparent bg-gradient-to-r ${gradient_from} ${gradient_to} border-b-[5px] inline-block`}>{platform}</span> Into Passive Income
            </h1>
            <p className="text-md md:text-xl mb-3 md:mb-40 md:w-[50%] mx-auto text-[#1a1f36] p-4 md:p-0 leading-relaxed">
              Your personal <span className={`bg-clip-text text-transparent bg-gradient-to-r ${gradient_from} ${gradient_to}`}>AI-powered clone</span> works with brands to produce content for you, generating pasive income while you live life.
            </p>
            <div className="flex flex-col gap-4 justify-center md:max-w-[400px] md:mx-auto">
              <Link
                to="/register"
                className={`px-2 md:px-8 py-2 md:py-4 bg-gradient-to-r ${gradient_from} ${gradient_to} text-white hover:opacity-90 rounded-full text-sm md:text-lg font-semibold transition-all tracking-tight`}
              >
                Join Now – Monetize Your Passion
              </Link>
              <Link
                to="/login"
                className={`px-2 md:px-8 py-2 md:py-4 bg-white border-2 border-[#1a1f36] text-[#1a1f36] hover:bg-gradient-to-r ${gradient_from}/10 ${gradient_to}/10 rounded-full text-sm md:text-lg font-semibold transition-all tracking-tight`}
              >
                Sign&nbsp;In
              </Link>
            </div>
            <p className="text-sm md:text-md text-[#1a1f36]/60 mt-4 tracking-tight">No credit card required • Start earning in minutes</p>
          </div>
        </div>
      </div>

      {/* Overcoming Self-Doubt Section */}
      <div className="container mx-auto px-4 pt-24 pb-0">
        <div className="max-w-3xl mx-auto text-center">
          <h2 className="text-3xl md:text-4xl font-semibold mb-6 text-[#1a1f36] tracking-tight">
            Think you're too small to make money?<br /><span className={`bg-clip-text text-transparent bg-gradient-to-r ${gradient_from} ${gradient_to}`}>Think again.</span>
          </h2>
          <p className="text-xl text-[#1a1f36] bg-white/40 backdrop-blur-sm rounded-2xl p-6 md:p-8 leading-relaxed">
            Our platform is built for every creator, turning your passion into profit—effortlessly.
          </p>
        </div>
      </div>

      {/* Benefits Section */}
      <div className="container mx-auto px-4 py-24">
        {/* First Benefit - Left Text, Right Image */}
        <div className="flex flex-col md:flex-row items-center gap-12 mb-24">
          <div className="w-full md:w-1/2 text-left">
            <div className={`text-4xl mb-6 bg-clip-text text-transparent bg-gradient-to-r ${gradient_from} ${gradient_to}`}>💰</div>
            <h3 className="text-3xl font-semibold mb-4 text-[#1a1f36] tracking-tight">Let's Make That BREAD</h3>
            <p className="text-xl text-[#1a1f36] leading-relaxed">
              Start earning from your content right away, regardless of your follower count. Our AI-powered platform makes it easy to integrate ads and start generating passive income.
            </p>
          </div>
          <div className="w-full md:w-1/2">
            <div className={`bg-white/40 backdrop-blur-sm rounded-2xl p-0 border ${gradient_from.replace('from-', 'border-')}/20 hover:${gradient_from.replace('from-', 'border-')}/40 transition-all aspect-[4/3] overflow-hidden`}>
              <img 
                src={storiaImage} 
                alt="Storia monetization example" 
                className="w-full h-full object-cover"
              />
            </div>
          </div>
        </div>

        {/* Second Benefit - Right Text, Left Image */}
        <div className="flex flex-col-reverse md:flex-row items-center gap-12 mb-24">
          <div className="w-full md:w-1/2">
            <div className={`flex flex-row bg-white/40 backdrop-blur-sm rounded-2xl p-0 border ${gradient_from.replace('from-', 'border-')}/20 hover:${gradient_from.replace('from-', 'border-')}/40 transition-all h-[300px] overflow-hidden relative`}>
              <video 
                className="w-full h-full object-contain rounded-lg  top-0 -left-32 z-10"
                autoPlay
                loop
                muted
                playsInline
              >
                <source src="https://cdn1.netizens.ai/canva1.webm" type="video/webm" />
              </video>
              <video 
                className="w-full h-full object-contain rounded-lg  top-0 left-0 z-20"
                autoPlay
                loop
                muted
                playsInline
              >
                <source src="https://cdn1.netizens.ai/canva2.webm" type="video/webm" />
              </video>
              <video 
                className="w-full h-full object-contain rounded-lg  top-0 left-32 z-30"
                autoPlay
                loop
                muted
                playsInline
              >
                <source src="https://cdn1.netizens.ai/canva3.webm" type="video/webm" />
              </video>
            </div>
          </div>
          <div className="w-full md:w-1/2 text-left">
            <div className={`text-4xl mb-6 bg-clip-text text-transparent bg-gradient-to-r ${gradient_from} ${gradient_to}`}>🤖</div>
            <h3 className="text-3xl font-semibold mb-4 text-[#1a1f36] tracking-tight">Give AI The Grunt Work</h3>
            <p className="text-xl text-[#1a1f36] leading-relaxed">
              Get creative support that automates ad reads and product visuals seamlessly. Our AI handles the heavy lifting while you focus on creating amazing content and living your authentic life.
            </p>
          </div>
        </div>

        {/* Third Benefit - Left Text, Right Image */}
        <div className="flex flex-col md:flex-row items-center gap-12">
          <div className="w-full md:w-1/2 text-left">
            <div className={`text-4xl mb-6 bg-clip-text text-transparent bg-gradient-to-r ${gradient_from} ${gradient_to}`}>⚡</div>
            <h3 className="text-3xl font-semibold mb-4 text-[#1a1f36] tracking-tight">Tailor Your Clone</h3>
            <p className="text-xl text-[#1a1f36] leading-relaxed">
              Choose full automation or approve ads yourself—you're in control. We give you the flexibility to manage your content and monetization strategy your own way.
            </p>
          </div>
          <div className="w-full md:w-1/2">
            <div className={`bg-white/40 backdrop-blur-sm rounded-2xl p-0 border ${gradient_from.replace('from-', 'border-')}/20 hover:${gradient_from.replace('from-', 'border-')}/40 transition-all aspect-[4/3] overflow-hidden`}>
              <img 
                src={img_feat1} 
                alt="Creator flexibility example" 
                className="w-full h-full object-cover"
              />
            </div>
          </div>
        </div>
      </div>

      {/* Final CTA Section */}
      <div className="container mx-auto px-4 py-24">
        <div className="max-w-3xl mx-auto text-center">
          <h2 className="text-3xl md:text-4xl font-semibold mb-6 text-[#1a1f36] tracking-tight">
            Ready to Turn Your Passion Into <span className={`bg-clip-text text-transparent bg-gradient-to-r ${gradient_from} ${gradient_to}`}>Profit</span>?
          </h2>
          <p className="text-xl text-[#1a1f36] mb-12 bg-white/40 backdrop-blur-sm rounded-2xl p-6 md:p-8 leading-relaxed">
            Join our community of creators who are already monetizing their content
          </p>
          <Link
            to="/register"
            className={`inline-block px-8 py-4 bg-gradient-to-r ${gradient_from} ${gradient_to} text-white hover:opacity-90 rounded-full text-sm md:text-lg font-semibold transition-all tracking-tight`}
          >
            Start Earning Today
          </Link>
          <p className="text-[#1a1f36]/60 mt-4 tracking-tight">No credit card required • Start earning in minutes</p>
        </div>
      </div>

      {/* Footer */}
      <footer className="bg-[#1a1f36] mt-24">
        <div className="container mx-auto px-4 py-12">
          <div className="flex flex-col md:flex-row justify-between items-start md:items-center gap-8">
            {/* Logo and Copyright */}
            <div className="flex flex-col gap-4">
              <Link to="/" className="text-2xl font-semibold hover:opacity-80 transition-opacity tracking-tight">
                <span className="text-white">NETIZENS</span>
                <span className={`bg-clip-text text-transparent bg-gradient-to-r ${gradient_from} ${gradient_to}`}>.ai</span>
              </Link>
              <p className="text-sm text-[#e5e5e5]">© {new Date().getFullYear()} Netizens AI. All rights reserved.</p>
            </div>

            {/* Navigation Links */}
            <div className="flex flex-col md:flex-row gap-8">
              <div className="flex flex-col gap-4">
                <h4 className="font-semibold text-white">Company</h4>
                <div className="flex flex-col gap-2">
                  <Link to="/about" className="text-[#e5e5e5] hover:text-white transition-colors">About</Link>
                  <Link to="/careers" className="text-[#e5e5e5] hover:text-white transition-colors">Careers</Link>
                  <Link to="/privacy" className="text-[#e5e5e5] hover:text-white transition-colors">Privacy</Link>
                  <Link to="/terms" className="text-[#e5e5e5] hover:text-white transition-colors">Terms</Link>
                </div>
              </div>

              <div className="flex flex-col gap-4">
                <h4 className="font-semibold text-white">Resources</h4>
                <div className="flex flex-col gap-2">
                  <Link to="/blog" className="text-[#e5e5e5] hover:text-white transition-colors">Blog</Link>
                  <Link to="/help" className="text-[#e5e5e5] hover:text-white transition-colors">Help Center</Link>
                  <Link to="/contact" className="text-[#e5e5e5] hover:text-white transition-colors">Contact</Link>
                </div>
              </div>

              {/* Social Links */}
              <div className="flex flex-col gap-4">
                <h4 className="font-semibold text-white">Social</h4>
                <div className="flex gap-4">
                  <a href="https://tiktok.com/@netizens.ai" target="_blank" rel="noopener noreferrer" className="text-[#e5e5e5] hover:text-white transition-colors">
                    <svg className="w-6 h-6" viewBox="0 0 24 24" fill="currentColor"><path d="M19.59 6.69a4.83 4.83 0 0 1-3.77-4.25V2h-3.45v13.67a2.89 2.89 0 0 1-5.2 1.74 2.89 2.89 0 0 1 2.31-4.64 2.93 2.93 0 0 1 .88.13V9.4a6.84 6.84 0 0 0-1-.05A6.33 6.33 0 0 0 5 20.1a6.34 6.34 0 0 0 10.86-4.43v-7a8.16 8.16 0 0 0 4.77 1.52v-3.4a4.85 4.85 0 0 1-1-.1z"/></svg>
                  </a>
                  <a href="https://discord.gg/netizens" target="_blank" rel="noopener noreferrer" className="text-[#e5e5e5] hover:text-white transition-colors">
                    <svg className="w-6 h-6" viewBox="0 0 24 24" fill="currentColor"><path d="M20.317 4.492c-1.53-.69-3.17-1.2-4.885-1.49a.075.075 0 0 0-.079.036c-.21.369-.444.85-.608 1.23a18.566 18.566 0 0 0-5.487 0 12.36 12.36 0 0 0-.617-1.23A.077.077 0 0 0 8.562 3c-1.714.29-3.354.8-4.885 1.491a.07.07 0 0 0-.032.027C.533 9.093-.32 13.555.099 17.961a.08.08 0 0 0 .031.055 20.03 20.03 0 0 0 5.993 2.98.078.078 0 0 0 .084-.026 13.83 13.83 0 0 0 1.226-1.963.074.074 0 0 0-.041-.104 13.175 13.175 0 0 1-1.872-.878.075.075 0 0 1-.008-.125c.126-.093.252-.19.372-.287a.075.075 0 0 1 .078-.01c3.927 1.764 8.18 1.764 12.061 0a.075.075 0 0 1 .079.009c.12.098.245.195.372.288a.075.075 0 0 1-.006.125c-.598.344-1.22.635-1.873.877a.075.075 0 0 0-.041.105c.36.687.772 1.341 1.225 1.962a.077.077 0 0 0 .084.028 19.963 19.963 0 0 0 6.002-2.981.076.076 0 0 0 .032-.054c.5-5.094-.838-9.52-3.549-13.442a.06.06 0 0 0-.031-.028zM8.02 15.278c-1.182 0-2.157-1.069-2.157-2.38 0-1.312.956-2.38 2.157-2.38 1.21 0 2.176 1.077 2.157 2.38 0 1.312-.956 2.38-2.157 2.38zm7.975 0c-1.183 0-2.157-1.069-2.157-2.38 0-1.312.955-2.38 2.157-2.38 1.21 0 2.176 1.077 2.157 2.38 0 1.312-.946 2.38-2.157 2.38z"/></svg>
                  </a>
                  <a href="https://youtube.com/@netizens.ai" target="_blank" rel="noopener noreferrer" className="text-[#e5e5e5] hover:text-white transition-colors">
                    <svg className="w-6 h-6" viewBox="0 0 24 24" fill="currentColor"><path d="M23.498 6.186a3.016 3.016 0 0 0-2.122-2.136C19.505 3.545 12 3.545 12 3.545s-7.505 0-9.377.505A3.017 3.017 0 0 0 .502 6.186C0 8.07 0 12 0 12s0 3.93.502 5.814a3.016 3.016 0 0 0 2.122 2.136c1.871.505 9.376.505 9.376.505s7.505 0 9.377-.505a3.015 3.015 0 0 0 2.122-2.136C24 15.93 24 12 24 12s0-3.93-.502-5.814zM9.545 15.568V8.432L15.818 12l-6.273 3.568z"/></svg>
                  </a>
                  <a href="https://instagram.com/netizens.ai" target="_blank" rel="noopener noreferrer" className="text-[#e5e5e5] hover:text-white transition-colors">
                    <svg className="w-6 h-6" viewBox="0 0 24 24" fill="currentColor"><path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z"/></svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default UGC; 