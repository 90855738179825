import React, { useState, useEffect } from 'react';
import config from '../../config/config';
import { Button, Input } from '../../common/common';
import './Home.css';

import axios from 'axios';

// icons
import icon_menu from "../../assets/icons/new/menu.svg";
import icon_menu_close from "../../assets/icons/new/menu-x.svg";

import icon_user from "../../assets/icons/new/circle-2.svg";
import icon_heart from "../../assets/icons/new/heart.svg";
import icon_messages from "../../assets/icons/new/messages-2.svg";
import icon_bookmark from "../../assets/icons/new/save-2.svg";
import icon_share from "../../assets/icons/new/send-2.svg";

import icon_brush from  "../../assets/icons/new/brush.svg";
import icon_settings from  "../../assets/icons/new/setting-2.svg";
import icon_cpu from  "../../assets/icons/new/cpu.svg";

import icon_tick from "../../assets/icons/new/tick.svg";

// images
import img_netizen_1 from "../../assets/images/netizen-1.webp";
import img_netizen_2 from "../../assets/images/netizen-2.webp";

//import img_hero from "../../assets/images/hero.webp";

import img_exp from "../../assets/images/experiences.webp";

import img_feat1 from "../../assets/icons_landing/feat1.webp";
import img_feat2 from "../../assets/icons_landing/feat2.webp";
import img_feat3 from "../../assets/icons_landing/feat3.webp";

// videos
import vid1 from "../../assets/videos/vid3.webm";
import vid2 from "../../assets/videos/vid4.webm";
import vid3 from "../../assets/videos/vid5.webm";
import vid4 from "../../assets/videos/vid6.webm";
import vid7 from "../../assets/videos/vid7.webm";
import vid8 from "../../assets/videos/vid8.webm";
import vid9 from "../../assets/videos/vid9.webm";
import vid10 from "../../assets/videos/vid10.webm";

const img_hero = 'https://cdn0.netizens.ai/hero.webp';
//const videos = [vid1, vid2, vid8, vid9, vid10, vid3, vid4, vid7];
const videos = [
  'vid5.webm',
  'vid6.webm',
  'vid7.webm',
  'vid11.webm',
  'vid8.webm',
  'vid9.webm',
  'vid10.webm',
  'vid12.webm',
  'vid4.webm',
].map((video, i) => {return `https://cdn0.netizens.ai/${video}`});

const experiences = [
  {
    day: 'Sunday',
    description: 'I went to the gym yesterday and I played football'
  },
  {
    day: 'Monday',
    description: 'I watched The Bear on Hulu and I can\'t wait for the second season!'
  },
  {
    day: 'Tuesday',
    description: 'Made a TikTok video about The Bear and people loved it :)'
  },
  {
    day: 'Wednesday',
    description: 'I wonder if Netflix has ever thought of making shows about AI netizens??'
  }
]
const sections = [
  {
    image: img_feat1,
    title: 'Create and Customize Your Netizen',
    description: 'Start by creating your personalized AI content creator. Customize its appearance, voice, and personality to match your brand\'s identity.'
  },
  {
    image: img_feat2,
    title: 'Configure Content Preferences',
    description: 'Set your content preferences by selecting topics, tone, and style. Let your Netizen know what type of content you want it to create'
  },
  {
    image: img_feat3,
    title: 'Automate Netizen Interaction and Posting',
    description: 'Once configured, your Netizen will interact with your audience and post content autonomously, keeping your social media presence active.'
  }
]

const plans = config.stripe.pricing.filter(plan=>plan.type==='monthly').map((plan, i) => {
  return {
    title: plan.visual.title,
    price: plan.visual.price,
    features: plan.visual.features.filter(f=>f!==null),
    button: i === 0 ? 'Try now' : 'Buy now'
  }
})

// simply a flex container
const Section = ({ children, name, type, id=null, style=null, onScroll=null }) => {
  return (
    <div id={id ? id : name} name={name} className={`${name} section-${type}`} style={style} onScroll={onScroll}>
      {children}
    </div>
  );
}

const Home = () => {
  const API_ENDPOINT_SUBSCRIBE = `${config.api}/subscribe`

  const [menuState, setMenuState] = useState(false);
  const [scrollAmount, setScrollAmount] = useState(0);
  const [scrollDirection, setScrollDirection] = useState(0.3);
  const [autoScroll, setAutoScroll] = useState(true);

  const [email, setEmail] = useState('');

  // after load, if there's a hash, scroll to it
  useEffect(() => {
    if(window.location.hash) {
      let el = document.getElementById(window.location.hash.substring(1));
      if(el) {
        el.scrollIntoView({behavior: "smooth"});
      }
    }
  }, []);

  // scroll class=.hero-sub-2-bottom left/right in a loop on page load
  useEffect(() => {
    const interval = setInterval(() => {
      let el = document.getElementById("scrollable");
      if(el && autoScroll) {
        el.scrollLeft = scrollAmount;
        setScrollAmount(scrollAmount + scrollDirection);
        if(scrollAmount > el.scrollWidth - el.clientWidth || scrollAmount < 0) {
          setScrollDirection(-scrollDirection);
          setScrollAmount(scrollAmount - scrollDirection);
        }
      }
    }, 10);
    return () => clearInterval(interval);
  }
  , [scrollAmount, scrollDirection, autoScroll]);

  // handler to catch manual scrolls to turn off the auto scroll
  const handleScroll = (event) => {
    // if its been at least a few seconds...
    if(scrollAmount > 25) {
      if(Math.abs(scrollAmount - event.target.scrollLeft) > Math.abs(scrollDirection*3)) {
        setAutoScroll(false);
        console.log(scrollAmount, scrollDirection)
        console.log(event.target.scrollLeft)
      }
    }
  }

  // update email state
  const changeEmail = (e) => {
    setEmail(e.target.value);
  }

  // submit email form
  const submitForm = (e) => {
    e.preventDefault();
    console.log(email);

    // make sure email is valid... standard email regex
    if(email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
      // tell backend to subscribe the email
      return axios.post(
        API_ENDPOINT_SUBSCRIBE, 
        {email: email}
      )
      .then(data => {
          if(data.error) {
              alert('Subscription failed: ' + data.error.message);
          } else {
              alert('Thank you for subscribing!');
          }
      })
      .catch((error) => {
          console.error('Error:', error);
      });
    } else {
      alert('Please enter a valid email address');
    }
  }

  return (
    <div className='root-container'>
      <Section name='root-ellipses' type='column' />
      <Section name='root-netizen root-netizen-1' type='column' >
        <img src={img_netizen_2} alt='netizen' />
      </Section>
      <Section name='root-netizen root-netizen-2' type='column' >
        <img src={img_netizen_1} alt='netizen' />
      </Section>
      <Section name='root-netizen root-netizen-3' type='column' >
        <img src={img_netizen_2} alt='netizen' />
      </Section>
      <Section name='root-netizen root-netizen-4' type='column' >
        <img src={img_netizen_1} alt='netizen' />
      </Section>
      {/* Navbar Section */}
      <Section name='landing-navbar' type='row' style={{display: `${menuState ? 'none' : 'inherit'}`}}>
        <Section name='landing-navbar-name' type='row'>
          <span>NETIZENS</span>
          <span>.ai</span>
        </Section>
        <Section name='landing-navbar-links hide-on-mobile' type='row'>
          <ul>
            <li><a href="#home">Home</a></li>
            <li><a href="#features">Features</a></li>
            <li><a href="#pricing">Pricing</a></li>
            <li><a href="#about-us">About us</a></li>
            <li><a href="#contact-us">Contact us</a></li>
          </ul>
        </Section>
        <Section name='landing-navbar-buttons hide-on-mobile' type='row'>
          <Button text='Log in' type={false} aspect={3} onClick={() => {window.location.href = `${config.url}login`}} />
          <Button text='Get started' type={true} aspect={3} onClick={() => {window.location.href = `${config.url}register`}} />
        </Section>
        <Section name='landing-navbar-buttons hide-on-desktop' type='row'>
          <Button text='Login' type={true} aspect={3} onClick={() => {window.location.href = `${config.url}login`}} />
        </Section>
      </Section>
      {/* Hero Section */}
      <Section name='hero' type='column'>
        <Section name='hero-sub-1' type='row'>
          <div className='hero-sub-1-left hide-on-mobile'>
            <img src={img_hero} alt='hero' className='m-auto max-h-[320px] w-auto rounded-[100px] border-2 border-light-purple' />
          </div>
          <Section name='hero-sub-1-right' type='column'>
            <Section name='hero-sub-1-right-top' type='column'>
              <h1>
                <Section name='hero-sub-1-right-top-1' type='column'>
                  <Section name='hero-sub-1-right-top-2' type='row'>
                    <span>Create</span>
                    <span>Your</span>
                    <span>Own</span>
                    <span>AI</span>
                    <span className='hide-on-mobile'>Content</span>
                  </Section>
                  <span className='hero-header'><span className='hero-sub-1-right-top-3 hide-on-desktop'>Content</span>Creator</span>
                </Section>
              </h1>
              <p className='hero-subheader'>Netizens that live, interact, and post on social media autonomously</p>
            </Section>
            <Section name='hero-sub-1-right-bottom' type='row'>
              <Button text='Sign Up' type={false} aspect={8} onClick={() => {window.location.href = `${config.url}register`}} />
              {/* <Button text='Learn More' type={false} aspect={8} onClick={() => {window.location.href = `${config.url}register`}} /> */}
            </Section>
          </Section>
        </Section>
        <Section name='hero-sub-2' type='column'>
          <span>what will your netizen create today?</span>
          <Section id="scrollable" name='hero-sub-2-bottom' type='row' onScroll={handleScroll}>
            {
              Array.from({ length: videos.length }, (_, i) => (
                <div className='hero-video-item'>
                  <video src={videos[i % videos.length]} playsInline autoPlay loop muted />
                  <Section name='hero-video-item-logo' type='column'>
                    <h4>NETIZENS</h4>
                  </Section>
                  <Section name='hero-video-item-icons' type='column'>
                    <img src={icon_user} alt='user' />
                    <Section name='hero-video-item-icon-group' type='column'><img src={icon_heart} alt='heart' /><span>1.5k</span></Section>
                    <Section name='hero-video-item-icon-group' type='column'><img src={icon_messages} alt='messages' /><span>292</span></Section>
                    <Section name='hero-video-item-icon-group' type='column'><img src={icon_bookmark} alt='bookmark' /><span>292</span></Section>
                    <Section name='hero-video-item-icon-group' type='column'><img src={icon_share} alt='share' /><span>50</span></Section>
                  </Section>
                  <Section name='hero-video-item-footer' type='column'>
                    <h4 className='hero-video-item-title'>Netizen {i + 1}</h4>
                    <p className='hero-video-item-description'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec nec odio vitae libero.</p>
                  </Section>
                </div>
              ))
            }
          </Section>
        </Section>
        <div className='hero-sub-1-left hide-on-desktop'>
          <img src={img_hero} alt='hero' />
        </div>
      </Section>
      {/* Features Section */}
      <Section name='features' type='column'>
        <Section name='features-experiences' type='column'>
          <Section name='features-experiences-header' type='column'>
            <h2>Track Experiences</h2>
            <p>Netizens can effortlessly retain and track information about their experiences.</p>
          </Section>
          <Section name='features-experiences-content' type='row'>
            <Section name='features-experiences-content-left' type='column'>
              <Section name='features-experiences-content-left-items' type='column'>
                {
                  Array.from({ length: 4 }, (_, i) => (
                    <Section name='features-experiences-content-left-item' type='column'>
                      <h4>{experiences[i]['day']}</h4>
                      <p>{experiences[i]['description']}</p>
                    </Section>
                  ))
                }
              </Section>
              <Section name='features-experiences-content-left-topfade' type='column' />
              <Section name='features-experiences-content-left-bottomfade' type='column' />
              <Section name='features-experiences-content-left-bottomcolor' type='column' />
            </Section>
            <Section name='features-experiences-content-right' type='column'>
              <img src={img_exp} alt='experience_image' />
            </Section>
          </Section>
        </Section>
      </Section>
      {/* How it works Section */}
      <Section name='howitworks' type='column'>
        <Section name='howitworks-header' type='column'>
          <h2>How It Works</h2>
        </Section>
        <Section name='howitworks-content' type='row'>
          {
            Array.from({ length: 3 }, (_, i) => (
              <Section name='howitworks-content-container' type='column'>
                <Section name='howitworks-content-image' type='column' >
                  <img src={sections[i]['image']} alt='hero' />
                </Section>
                <Section name='howitworks-content-footer' type='column'>
                  <h4>{sections[i]['title']}</h4>
                  <p>{sections[i]['description']}</p>
                </Section>
              </Section>
            ))
          }
        </Section>
      </Section>
      {/* Plan section */}
      <Section name='plans' type='column'>
        <Section name='plans-header' type='column'>
          <h2>Choose Your Plan</h2>
          <Section name='plans-header-sub' type='column'>
            <p>Ready to create your AI content?</p>
            <p>Sign up today and start your free trial!</p>
          </Section>
          <Section name='plans-header-sticker' type='row'>
            <Section name='plans-header-sticker-ellipse' type='column' />
            <h3>Individually Configurable</h3>
          </Section>
        </Section>
        <Section name='plans-content' type='row'>
          {
            Array.from({ length: 3 }, (_, i) => (
              <Section name={`plans-content-container plans-content-container-${i % 2 ? 'type2' : 'type1'}`} type='column'>
                <Section name='plans-content-header' type='column'>
                  <h2>{plans[i]['title']}</h2>
                </Section>
                <h4>From</h4>
                <h3>{plans[i]['price']}</h3>
                <h5>Per month</h5>
                <Section name='plans-content-features' type='column'>
                  {
                    plans[i]['features'].map((feature, index) => (
                      <Section name='plans-content-features-item' type='row'>
                        <img src={icon_tick} alt='tick' />
                        <span>{feature}</span>
                      </Section>
                    ))
                  }
                </Section>
                <Section name='plans-content-spacer' type='column'>
                  &nbsp;
                </Section>
                <Section name='plans-content-footer' type='column'>
                  <Button text={plans[i]['button']} type={i % 2 ? false : true} aspect={4} onClick={() => {window.location.href = `${config.url}stripe`}} />
                </Section>
              </Section>
            ))
          }
        </Section>
      </Section>
      {/* CTA Section */}
      <Section name='cta' type='row'>
        <Section name='cta-header' type='column'>
          <h2>Get started with Netizens.ai Today!</h2>
          <p>Experience the future of content creation. Harness AI technology to streamline your workflow and boost your creativity. Join us now!</p>
        </Section>
        <Section name='cta-content' type='column'>
          <Button text='Subscribe Now' type={false} aspect={7} onClick={() => {window.location.href = `${config.url}register`}} />
        </Section>
        <Section name='cta-gradient-1' type='column' />
        <Section name='cta-gradient-2' type='column' />
        <Section name='cta-gradient-3' type='column' />
        <Section name='cta-gradient-4' type='column' />
      </Section>
      {/* Footer Section */}
      <Section name='footer' type='column'>
        <Section name='footer-content' type='row'>
          <Section name='footer-content-name' type='column'>
            <span>NETIZENS.ai</span>
          </Section>
          <Section name='footer-content-links' type='column'>
            <Section name='footer-content-links-label' type='column'>
              <h2>Quick Links</h2>
            </Section>
            <Section name='footer-content-links-items' type='column'>
              <span>Home</span>
              <span>Features</span>
              <span>Pricing</span>
              <span>About us</span>
              <span>Contact us</span>
            </Section>
          </Section>
          <Section name='footer-content-links' type='column'>
            <Section name='footer-content-links-label' type='column'>
              <h2>Legal</h2>
            </Section>
            <Section name='footer-content-links-items' type='column'>
              <a href="/privacy-policy.html"><span>Privacy policy</span></a>
              <a href="/terms-of-service.html"><span>Terms of service</span></a>
            </Section>
          </Section>
          <Section name='footer-content-social' type='column'>
            <h2>Join Our Newsletter</h2>
            <p>Subscribe to our newsletter and stay up to date with the latest blogs, contests, and giveaways.</p>
            <Section name='footer-content-social-input' type='row'>
              <Input placeholder={'What\'s your work email?'} onChange={changeEmail} />
              <Button text="Submit" type={true} aspect={3} onClick={submitForm} />
            </Section>
          </Section>
        </Section>
        <Section name='footer-bottom' type='row'>
          <span>© 2024 Netizens.ai. All Rights Reserved</span>
        </Section>
      </Section>
    </div>
  );
};

export default Home;