import React, { useState, useRef, useEffect } from 'react';

import config from '../../config/config';
import axios from 'axios';
import globalConfig from '../../config/config';
import { getAuth, authHeader, updateUser } from '../../utils/auth';

import Fields from '../common/Fields';
import NewView from "../common/NewView";
import OneOffAlert from './OneOffAlert';

const OneOffMain = ({backEvent, setBackEvent, setBackEnabled}) => {
  const API_ENDPOINT_JOBS = `${globalConfig.api}/renderjobs`
  const API_ENDPOINT_PIPELINES = `${globalConfig.api}/pipelines`
  const API_ENDPOINT_NETIZENS = `${globalConfig.api}/netizens`
  const API_ENDPOINT_VOICES = `${globalConfig.api}/voices`
  const API_ENDPOINT_MODELS = `${globalConfig.api}/models`
  const S3_PATH_PREFIX = `https://cdn1.netizens.ai`

  const DEMOS = [
    {
      label: "Tia Podcast (Demo)",
      value: 0,
      image_horizontal: S3_PATH_PREFIX + "/demo_scenes/podcast2.png",
      image_vertical: S3_PATH_PREFIX + "/demo_scenes/podcast2.png",
      script: "Hey, babes! Welcome back to Tia Show! You already know, it’s your girl, Tia Tessa Smith, bringing you all the good energy, real talk, and just a lil’ bit of tea. Grab your matcha, settle in, and let’s get into it—today’s topic is sooo juicy, you do not wanna miss this!",
      video: S3_PATH_PREFIX + "/demo_scenes/podcast2_small.mp4",
      credits: 10,
    },
    {
      label: "Just Chatting (Demo)",
      value: 1,
      image_horizontal: S3_PATH_PREFIX + "/demo_scenes/podcast.png",
      image_vertical: S3_PATH_PREFIX + "/demo_scenes/podcast.png",
      script: "Hey, babes! Welcome back to Tia Show! You already know, it’s your girl, Tia Tessa Smith, bringing you all the good energy, real talk, and just a lil’ bit of tea. Grab your matcha, settle in, and let’s get into it—today’s topic is sooo juicy, you do not wanna miss this!",
      video: S3_PATH_PREFIX + "/demo_scenes/podcast_small.mp4",
      credits: 10,
    },
    // {
    //   label: "Selfie On The Bed",
    //   value: 2,
    //   image: "https://assets.superhivemarket.com/store/productimage/535338/image/f6c81950898fa3288e9a86045df48578.jpg",
    //   script: "Welcome fellow degenerates! I am a netizen created by the netizens.ai platform. What do you want me to post about?",
    //   video: "https://cdn1.netizens.ai/2bc72b6f43fb6fe31da92859413c4830.mp4",
    // },
    // {
    //   label: "Chilling on the Couch",
    //   value: 3,
    //   image: "https://assets.superhivemarket.com/store/productimage/535339/image/5c5be0ec895a8fdd0b3be8fc73907704.jpg",
    //   script: "Demo 2!!!!",
    //   video: "https://cdn1.netizens.ai/fafa138ce766623d981442896ccaa78e.mp4",
    // },
    {
      label: "Podcast Conversations",
      value: 2,
      image_horizontal: S3_PATH_PREFIX + "/demo_scenes/podcast2.png",
      image_vertical: S3_PATH_PREFIX + "/demo_scenes/podcast2.png",
      credits: 20,
    },
    // {
    //   label: "Recent News",
    //   value: 5,
    //   image: "https://assets.superhivemarket.com/store/productimage/535339/image/5c5be0ec895a8fdd0b3be8fc73907704.jpg",
    //   script: "Demo 2!!!!",
    //   video: "https://cdn1.netizens.ai/fafa138ce766623d981442896ccaa78e.mp4",
    // }
  ]

  const success_title = "Success!"
  const success_description = "Next, generate unlimited videos and styles with your own custom voice and avatar. Don't let other channels beat yours, the race is on!"

  const [style, setStyle] = useState(0);
  const [activeDemo, setActiveDemo] = useState(null);
  const [activeVideo, setActiveVideo] = useState(null);

  const [createEnabled, setCreateEnabled] = useState(true);

  const [progress, setProgress] = useState(0);
  const [showAlert, setShowAlert] = useState(false);

  const word_limit = 100;
  const [scriptEnabled, setScriptEnabled] = useState(false);
  const [scriptSublabel, setScriptSublabel] = useState(`Word limit: ${word_limit}`)
  const [script, setScript] = useState(null);

  const [userCredits, setUserCredits] = useState(0);
  const [numCredits, setNumCredits] = useState(0);

  const [activeJob, setActiveJob] = useState(null);

  // get user credits
  useEffect(() => {
    updateUser().then((user) => {
      if(user) {
        setUserCredits(user.credits || 0);
      }
    });
  }, []);

  // update create enabled based on user credits
  useEffect(() => {
    if (userCredits < numCredits) {
      setCreateEnabled(false);
    } else {
      setCreateEnabled(true);
    }
  }, [userCredits, numCredits]);

  // check to see if the user has an running job on page load
  useEffect(() => {
    axios.get(API_ENDPOINT_JOBS, {
        headers: {
          ...authHeader()
        },
        params: {
          netizen: getAuth().user.netizen
        }
      })
      .then(res => {
        let jobs = res?.data?.results
        let runningJob = jobs.find(job => job.status.toLowerCase() === 'running')

        if (runningJob) {
          console.log('Running job:', runningJob)
          runningJob.start_time = new Date().toISOString() // update start time to now
          setActiveJob(runningJob)
          setProgress(0.01)
        }
      })
      .catch(error => {
        console.error(error);
      })
  }, []);


  // update progress
  useEffect(() => {
    // in the case that there's no job, wait a set amount of time before showing the alert
    if (!activeJob) {
      if (progress > 0 && progress < 1) {
        const timer = setTimeout(() => {
          let new_progress = Math.min(progress + 0.01, 1)

          setProgress(new_progress);
        }, 100);
        return () => clearTimeout(timer);
      }
    } else {
      // if there is a job, check the status
      const interval = setInterval(async () => {
        try {
          let res = await axios.get(`${API_ENDPOINT_JOBS}/${activeJob.id}`, {
              headers: {
                ...authHeader()
              }
            })
            .catch(error => {
              console.error(error);
              throw error;
            })

          let newJob = res.data
          console.log(newJob)

          // if the job is done, set the progress to 1
          if (newJob.status.toLowerCase() === 'completed') {
            console.log('Job completed', newJob)

            let newVideo = newJob?.config?.output?.result?.[0]?.media?.s3
            if (newVideo) {
              let newVideoUrl = `${S3_PATH_PREFIX}/${newVideo}`
              setActiveVideo(newVideoUrl)
              setProgress(1)
              setActiveJob(null)
            } else {
              console.error('No video found in job output')
              // just let the load keep spinning... sorry
            }
          } else {
            // average job time is 10 minutes, so divide elapsed time by that to get progress
            let start_time = new Date(activeJob.start_time)
            let elapsed_time = new Date() - start_time
            let new_progress = Math.max(Math.min(elapsed_time / (10.0 * 60.0 * 1000.0), 1), 0.01)
            setProgress(new_progress);
          }
        } catch (error) {
          console.error(error);
        }
      }, 2000);
      return () => clearInterval(interval);
    }
  }, [progress]);

  // change alert state based on progress
  useEffect(() => {
    if (progress >= 1) {
      setShowAlert(true);
    } else {
      setShowAlert(false);
    }
  }, [progress]);

  // update active demo & script
  useEffect(() => {
    let _demo = DEMOS.find(d => d.value == style) // don't want strict equality here
    setActiveDemo(_demo)

    if (_demo && _demo.script) {
      setScriptEnabled(false)
      setScript(_demo.script)
    } else {
      setScriptEnabled(true)
      setScript('')
    }

    if (_demo && _demo.credits) {
      setNumCredits(_demo.credits)
    } else {
      setNumCredits(0)
    }
  }, [style]);

  // update active video
  useEffect(() => {
    let _video = activeDemo ? activeDemo.video : ''
    setActiveVideo(_video)
  }, [activeDemo]);

  // handle creation
  const onClickCreate = async () => {
    if (!createEnabled) {
      onClickUpgrade()
      return
    }


    if (!activeDemo) {
      onClickUpgrade()
      return
    }

    // if the active demo doesn't have a video, do a real creation
    if (!activeDemo.video) {
      onClickCreateReal()
      setProgress(0.01);
    } else {
      setProgress(0.01);
    }
  }

  // handle upgrade
  const onClickUpgrade = async () => {
    window.open('https://netizens.ai/stripe', '_blank');
  }

  // handle close alert
  const onClickClose = async () => {
    setProgress(0);
  }

  // handle download alert
  const onClickDownload = async () => {
    // download video
    let url = activeDemo ? activeDemo.video : ''
    window.open(url, '_blank');
  }

  // handle creation
  const onClickCreateReal = async () => {
    try {
      let res =  null;

      // create a netizen based on the info, if it doesn't already exist
      const netizenId = "67ae59ec2f8926bab155e7ea"

      // create a pipeline, if it doesn't already exist
      const pipelineName = "test pipeline"
      const pipelineDescription = "automatically generated test pipeline"
      const pipelineType = "ScriptPipeline"
      const pipelineCredits = numCredits
      const pipelineConfig = {
        scene: "67ae8191e059ee009d3d4373", // podcast 4
        credits: pipelineCredits,
        num_experiences: 1,
        generate_selfie: true,
        generate_subtitles: false,
        script: script,
      }

      // try to find a pipeline with this name
      res = await axios.get(API_ENDPOINT_PIPELINES, {
          headers: {
            ...authHeader()
          },
          params: {
            name: pipelineName
          }
        })
        .catch(error => {
          console.error(error);
          throw error;
        })
        
      let newPipeline = null
      if (res.data.results.length > 0) {
        // if it exists, use it
        console.log('Pipeline already exists, so update it')
        newPipeline = res.data.results[0]
      }

      // post to endpoint
      let pipelineBody = {
        name: pipelineName,
        description: pipelineDescription,
        public: false,
        type: pipelineType,
        config: pipelineConfig
      }

      if (!newPipeline) {
        res = await axios.post(API_ENDPOINT_PIPELINES, pipelineBody, {
            headers: {
              ...authHeader()
            }
          })
          .catch(error => {
            console.error(error);
            throw error;
          })

        newPipeline = res.data
      } else {
        res = await axios.patch(`${API_ENDPOINT_PIPELINES}/${newPipeline.id}`, pipelineBody, {
            headers: {
              ...authHeader()
            }
          })
          .catch(error => {
            console.error(error);
            throw error;
          })
        newPipeline = res.data
      }

      console.log(newPipeline)

      // if successful, create a one off job
      let jobBody = {
        config: {
          ...newPipeline.config,
          netizens: [
            netizenId
          ],
        },
      }

      res = await axios.post(`${API_ENDPOINT_PIPELINES}/${newPipeline.id}/test`,
        jobBody, 
        {
          headers: {
            ...authHeader()
          }
        })
        .catch(error => {
          console.error(error);
          throw error;
        })

      let newJob = res.data
      newJob.start_time = new Date().toISOString()
      console.log(newJob)

      // set active job
      setActiveJob(newJob)

      // set message
      setScriptEnabled(false);
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <NewView>
      <OneOffAlert
        title={success_title}
        description={success_description}
        url={activeVideo}
        visible={showAlert}
        onClickClose={onClickClose}
        onClickDownload={onClickDownload}
      />

      <div className="flex justify-between items-center mb-4 space-x-20 hidden md:block">
        <div className>
          <div className="flex items-center space-x-3">
            <h1 className="text-lg font-regular m-0 mr-2">
              Generate
            </h1>
          </div>
        </div>
      </div>
      
      <div className="flex flex-col justify-end h-full overflow-hidden">
        <div className="flex h-2/3 flex-row justify-between">
          <div className="flex w-2/3 flex-col sm:flex-row justify-between sm:space-x-10 pr-5 mb-4">
            <div className="flex flex-col grow w-full h-full min-h-0">
              {!(progress > 0 && progress < 1) && <Fields.ImageResponsive
                label="Netizen"
                sublabel=""
                info={""} 
                contain={true}
                url={activeDemo ? [activeDemo.image_horizontal, activeDemo.image_vertical, activeDemo.image] : ''}
              />}
              {(progress > 0 && progress < 1) && <Fields.Loader
                title="Loading..."
                progress={progress}
              />}
            </div>
          </div>

          <div className="flex w-1/3 flex-col gap-4 justify-between sm:space-x-10 pr-1 mb-4">
            <div className="w-full flex h-2/3 flex-col">
              <Fields.EnumField3
                label="Demo"
                sublabel=""
                info={""} 
                disabled={progress > 0 && progress < 1}
                value={style} 
                setValue={
                  (value) => {
                    if(progress > 0 && progress < 1) {
                      return
                    } else {
                      setStyle(value)
                    }
                  }} 
                values={DEMOS} 
                margin={false}
                list={true}
                scroll={true}
              />
            </div>

            <div className="flex w-full h-1/3 flex-col gap-1 justify-end !ml-0">
              <div className="flex flex-col !mb-0 !ml-0">
                <Fields.Button type='wide-styled' css='h-[60px]' label={createEnabled ? `Create (${numCredits} credits)` : 'Not enough credits'} onClick={()=>onClickCreate()} id="demo-create-button" disabled={(progress > 0 && progress < 1)} />
              </div>

              <div className="flex flex-col !mb-0 !ml-0">
                <Fields.Button type='wide-success' label={'Upgrade'} onClick={()=>onClickUpgrade()} id="demo-upgrade-button" disabled={progress > 0 && progress < 1} />
              </div>
            </div>
          </div>
        </div>

        <div className="flex h-1/3 flex-col justify-end space-y-0">
          <Fields.DescriptionField 
            label="Script" 
            placeholder="Script" 
            sublabel={scriptSublabel}
            sublabeltype={1}
            disabled={!scriptEnabled || (progress > 0 && progress < 1)}
            value={script}
            fullheight={true}
            onChange={(e) => {
              const words = e.target.value.split(' ').filter(word => word.length > 0);
              if (words.length > word_limit) {
                setScriptSublabel(`Word limit reached: ${word_limit}`);
                setScript(script.trim())
              } else {
                setScriptSublabel(`Word limit: ${word_limit - words.length}`);
                setScript(e.target.value)
              }
            }}
          />
        </div>
      </div>
    </NewView>
  )
}

const OneOffReal = ({backEvent, setBackEvent, setBackEnabled}) => {
  return (
    <OneOffMain 
      backEvent={backEvent} 
      setBackEvent={setBackEvent} 
      setBackEnabled={setBackEnabled} 
    />
  );
}

export default OneOffReal;